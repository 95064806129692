import React from "react"
import { Fade } from "react-awesome-reveal";
import { GatsbyImage } from "gatsby-plugin-image";

const TeamTile = ({ smallTitle, title, text, image, alt }) => {
  return (
    <div className="tile">
      <Fade triggerOnce>
        <div className="row no-gutters">
          <div className="col">
            <div className="tile-image">
              <GatsbyImage image={image} alt={alt} />
            </div>
          </div>
          <div className="col">
            <div className="content-wrapper">
              {smallTitle &&
                <p className="subtitle">{smallTitle}</p>
              }
              <h3>{title}</h3>
              <p>{text}</p>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  )
}

export default TeamTile
