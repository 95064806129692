import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"
import TeamTiles from "../components/teamTiles"
import Hero from "../components/hero";

const OurTeam = ({ data }) => {

  const heroContent = data.markdownRemark.frontmatter.hero;
  const tilesContent = data.markdownRemark.frontmatter.team_members;

  return (
    <Layout classes="team">
      <Seo title={heroContent.title} description={heroContent.caption} />
      <Hero heroContent={heroContent} />
      <TeamTiles tilesContent={tilesContent} />
    </Layout>
  );
}

export const query = graphql`{
  markdownRemark(frontmatter: {template_key: {eq: "our-team"}}) {
    frontmatter {
      title
      hero {
        small_title
        title
        caption
      }
      team_members {
        image {
          childImageSharp {
            gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
          }
        }
        small_title
        title
        text
      }
    }
  }
}
`

export default OurTeam
