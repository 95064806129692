import React from "react"
import TeamTile from "./teamTile"

const TeamTiles = ({ tilesContent }) => {
  return (
    <section className="tiles">
      <div className="container">
        {tilesContent.map((tile, index) =>
          <TeamTile smallTitle={tile.small_title} title={tile.title} text={tile.text} image={tile.image.childImageSharp.gatsbyImageData} alt={tile.title} key={index} />
        )}
      </div>
    </section>
  );
}

export default TeamTiles
